import { environment } from '../environment/environment';

export const ROUTING_KEY_MAPPING = {
  LANDING_FULL_TEST: 'online-test-landing',
  LANDING_READING_PRACTICE_TEST: 'reading-practice-landing',
  LANDING_LISTENING_PRACTICE_TEST: 'listening-practice-landing',
  LANDING_WRITING_SAMPLE: 'writing-sample-landing',
  LANDING_SPEAKING_SAMPLE: 'speaking-sample-landing',
  FULL_TEST: 'full-test',
  PRACTICE_TEST: 'practice-test',
  VIEW_VOCAB: 'view-vocab',
  VIEW_SOLUTION: 'view-solution',
  VIEW_TEST_QUESTION: 'view-de',
  VIEW_TRANSCRIPT: 'view-transcript',
  DO_TEST: 'do-test',
  BOOK: 'book-seo-page',
  LANDING_HOME: 'landing_home',
  LANDING_WRITING_IELTS_BLOG: 'writing-blog-landing',
  LANDING_WRITING_SAMPLE_T1: 'writing-sample-task1-landing',
  LANDING_WRITING_SAMPLE_T2A: 'writing-sample-task2-aca-landing',
  LANDING_WRITING_SAMPLE_T2G: 'writing-sample-task2-general-landing',
  LANDING_SPEAKING_IELTS_BLOG: 'speaking-blog-landing',
  LANDING_SPEAKING_SAMPLE_P1: 'speaking-sample-part1-landing',
  LANDING_SPEAKING_SAMPLE_P2: 'speaking-sample-part2-landing',
  LANDING_SPEAKING_SAMPLE_P3: 'speaking-sample-part3-landing',
  WRITING_SAMPLE_DETAIL: 'writing-sample-detail',
  SPEAKING_SAMPLE_DETAIL: 'speaking-sample-detail',
  LANDING_GRAMMAR_IELTS_BLOG: 'grammar-blog-landing',
  LANDING_VOCAB_IELTS_BLOG: 'vocab-blog-landing',
  LANDING_K12_STANDARD_TEST: 'landing-k12-standard-test',
  LANDING_IELTS_KNOWLEDGE: 'ielts-knownledge',
};

// TODO: Please use this create dynamic links
// https://nextjs.org/docs/routing/introduction#linking-to-dynamic-paths

export const routingUrlMapValueToKey = (value) => {
  return Object.keys(ROUTING_KEY_MAPPING).find((key) => ROUTING_KEY_MAPPING[key] === value);
};

export const routingUrls = {
  LOGIN_PATH: '/login',

  error: {
    forbidden403: '/forbidden',
    notFound404: '/404',
  },

  onlineTest: {
    ROOT_PATH: environment.REACT_APP_ONLINE_TEST_ROOT_PATH,
    ADMIN_PATH: '/admin',
    MARKETER_ALL_LANDINGS_DOL_ENGLISH: '/admin/dol-english/all-landings',

    ADMIN_READING_SECTIONS_PATH: '/admin/reading-test-sections',
    ADMIN_LISTENING_SECTIONS_PATH: '/admin/listening-test-sections',
    PREVIEW_DICTIONARY: '/admin/dictionary-preview/[id]/preview',
    PREMIUM_USER: '/admin/accounts/premium-user',
    REWARDS: '/admin/accounts/rewards',
    GIFT_CODE: '/admin/accounts/gift-code',

    ADMIN_READING_TEST_SECTIONS_SETUP_PATH: (testSectionId) =>
      `/admin/reading-test-sections/${testSectionId}/setup`,
    ADMIN_READING_TEST_SECTIONS_PREVIEW_PATH: (testSectionId) =>
      `/admin/reading-test-sections/${testSectionId}/preview`,

    ADMIN_TEST_SECTION_READING_CREATE: '/admin/reading-test-sections/new',
    ADMIN_TEST_SECTION_READING_UPDATE: '/admin/reading-test-sections/[testSectionId]/setup',
    ADMIN_TEST_SECTION_READING_PREVIEW: '/admin/reading-test-sections/[testSectionId]/preview',
    ADMIN_TEST_SECTION_READING_VIEW_HISTORY: '/admin/reading-test-sections/histories/[historyId]',

    ADMIN_TEST_SECTION_LISTENING_CREATE: '/admin/listening-test-sections/new',
    ADMIN_TEST_SECTION_LISTENING_UPDATE: '/admin/listening-test-sections/[testSectionId]/setup',
    ADMIN_TEST_SECTION_LISTENING_PREVIEW: '/admin/listening-test-sections/[testSectionId]/preview',
    ADMIN_TEST_SECTION_LISTENING_VIEW_HISTORY:
      '/admin/listening-test-sections/histories/[historyId]',

    ADMIN_TEST_PREVIEW: '/admin/tests/[id]/preview',
    ADMIN_TEST_SETUP: '/admin/tests/[id]/setup',
    ADMIN_TEST_EDIT: '/admin/tests/[id]/edit',

    ADMIN_LISTENING_TEST_SECTIONS_SETUP_PATH: (testSectionId) =>
      `/admin/listening-test-sections/${testSectionId}/setup`,
    ADMIN_LISTENING_TEST_SECTIONS_PREVIEW_PATH: (testSectionId) =>
      `/admin/listening-test-sections/${testSectionId}/preview`,

    ADMIN_TEST_PATH: '/admin/tests',
    ADMIN_TEST_PREVIEW_PATH: (testId) => `/admin/tests/${testId}/preview`,
    ADMIN_TEST_PREVIEW_PATH_2: '/admin/tests/[id]/preview',
    ADMIN_TEST_EDIT_PATH: (testId, skill?: string) =>
      `/admin/tests/${testId}/edit${skill ? `?skill=${skill}` : ''}`,

    ADMIN_SEO_PATH: 'admin/ielts/seo-info',
    ADMIN_IELTS_SEO_PREVIEW_ORDER_LANDING_PAGE_EDIT_PATH:
      '/admin/ielts/seo-info/landing/[templateType]/preview-order/edit',
    ADMIN_SEO_TEST_DETAIL_PAGE_EDIT_PATH:
      '/admin/ielts/seo-info/test-detail/[templateType]/[testType]/[id]/edit',
    ADMIN_SEO_TEST_LANDING_PAGE_EDIT_PATH: '/admin/ielts/seo-info/landing/[templateType]/edit',
    ADMIN_SEO_TEST_LANDING_HOME_PAGE_EDIT_PATH: '/admin/home/seo-info/edit',
    ADMIN_EDIT_HOME_PAGE: '/admin/home-page-preview',
    ADMIN_EDIT_SEO_HOME_PAGE: '/admin/home-page-seo',
    ADMIN_BOOK_SEO_PAGE_EDIT_PATH: `/admin/ielts/seo-info/${ROUTING_KEY_MAPPING.BOOK}/[bookSeoPageId]/edit`,

    ADMIN_PREVIEW_BOOK_PATH: '/admin/book/preview',
    ADMIN_PREVIEW_TEST_QUESTION_PATH: '/admin/ielts/preview/view-test-question',

    ADMIN_ONLINE_TEST_LANDING: '/admin/ielts/ielts-online-test-landing',
    ADMIN_NEWLY_UPDATED_TESTS_SETUP: '/admin/ielts/newly-updated-tests/setup',

    MARKETER_READING_PRACTICE_LANDING: '/admin/ielts/marketer-practice-test-passage',
    MARKETER_LISTENING_PRACTICE_LANDING: '/admin/ielts/marketer-practice-test-section',

    ADMIN_FULL_TEST_WITH_URLS: '/admin/ielts/marketer-full-test',

    ADMIN_IELTS_BOOK_PATH: '/admin/ielts/books',
    ADMIN_IELTS_CREATE_BOOK_PATH: '/admin/ielts/books/create',
    ADMIN_IELTS_BOOK_DETAIL_PATH: (id) => `/admin/ielts/books/${id}/detail`,
    ADMIN_IELTS_EDIT_BOOK_PATH: '/admin/ielts/books/[bookId]/edit',
    ADMIN_IELTS_BOOK_SELECT_TESTS_PATH: (id) => `/admin/ielts/books/${id}/select-tests`,

    ADMIN_IELTS_READING_PRACTICE_LANDING: '/admin/ielts/reading-practice-landing',
    ADMIN_IELTS_LISTENING_PRACTICE_LANDING: '/admin/ielts/listening-practice-landing',
    ADMIN_IELTS_READING_PRACTICE_ORDERING: '/admin/ielts/reading-practice-landing/preview/ordering',
    ADMIN_IELTS_LISTENING_PRACTICE_ORDERING:
      '/admin/ielts/listening-practice-landing/preview/ordering',

    ADMIN_BLOG_LINEAR_LISTING: '/admin/blogs/linear-listing',
    ADMIN_BLOG_LINEAR_ALL_URLS: '/admin/blogs/linear-all-urls',
    ADMIN_BLOG_SEO_INFO_LANDING: '/admin/blogs/seo-info/landing/edit',
    ADMIN_BLOG_SEO_INFO_DETAIL: '/admin/blogs/seo-info/detail-post/[blogId]/edit',

    ADMIN_DICTATION_PATH: '/admin/dictations',
    ADMIN_CREATE_DICTATION_PATH: '/admin/dictations/create',
    ADMIN_SETUP_DICTATION_PATH: (id) => `/admin/dictations/${id}/setup`,
    ADMIN_PREVIEW_DICTATION_PATH: (id) => `/admin/dictations/${id}/preview`,
    ADMIN_DICTATION_PREVIEW: '/admin/dictations/[id]/preview',
    ADMIN_DICTATION_URLS_PATH: '/admin/dictation-urls',
    ADMIN_DICTATION_LISTING_PATH: '/admin/dictation-listing',
    ADMIN_DICTATION_SEO_INFO_LANDING: '/admin/dictation-urls/seo-info/landing/edit',
    ADMIN_DICTATION_PREVIEW_ORDERING: '/admin/dictation-urls/preview/ordering',
    ADMIN_DICTATION_SEO_INFO_DETAIL: '/admin/dictation-urls/seo-info/detail/[dictationId]/edit',

    STUDENT_VIEW_RESULT_PATH: (testId) => `/view-result/${testId}`,
    STUDENT_DO_TEST_PATH: (testId) => `/do-test/${testId}`,
    STUDENT_DO_TEST: '/do-test/[userTestId]',
    STUDENT_START_TEST: '/start-test/[testId]',
    STUDENT_TEST_RESULT_PATH: (testId) => `/test-result/${testId}`,
    ADMIN_ONLINE_TEST_WRITE_VOCAB_PATH: '/admin/dictations',
    ADMIN_ONLINE_TEST_ACCOUNT_MANA_PATH: '/admin/accounts/account-management',
    ADMIN_ONLINE_TEST_URL_MANA_PATH: '/admin/ielts/url-management',
    ADMIN_ONLINE_TEST_HOME_MANA_PATH: '/admin/ielts/home-management',
    ADMIN_SEO_REDIRECT_MANA_PATH: '/admin/seo/redirect',

    ADMIN_API_AUTHS_PATH: '/admin/impersonate',
    ADMIN_CREATE_API_AUTHS_PATH: '/admin/api-management/api-auths/create',
    ADMIN_EDIT_API_AUTHS_PATH: '/admin/api-management/api-auths/[id]',
    ADMIN_APPS_PATH: '/admin/api-management/apps',
    // BLOG
    ADMIN_IELTS_READING_BLOG: '/admin/blog-management/reading-blog',
    ADMIN_IELTS_LISTENING_BLOG: '/admin/blog-management/listening-blog',
    ADMIN_IETLS_BLOG_MANAGEMENT_CREATE: '/admin/blog-management/[blogType]/setup',
    ADMIN_IETLS_BLOG_MANAGEMENT_UPDATE: '/admin/blog-management/edit/[id]',
    ADMIN_IELTS_BLOG_PREVIEW_PATH: (blogId) => `/admin/blog-management/preview/${blogId}`,

    // BLOG LISTING FOR MARKETER
    ADMIN_IETLS_READING_BLOG_LISTING: '/admin/ielts/blog-listing/reading-blog',
    ADMIN_IETLS_LISTENING_BLOG_LISTING: '/admin/ielts/blog-listing/listening-blog',
    ADMIN_IETLS_SPEAKING_BLOG_LISTING: '/admin/ielts/blog-listing/speaking-blog',
    ADMIN_IETLS_WRITING_BLOG_LISTING: '/admin/ielts/blog-listing/writing-blog',
    ADMIN_BLOG_LISTING_EDIT_SEO_INFO: '/admin/ielts/blog-listing/seo-info/[id]',
    ADMIN_IETLS_BLOG_WITH_PRACTICE_TEST_LISTING: '/admin/ielts/READING',
    ADMIN_LISTENING_IETLS_BLOG_WITH_PRACTICE_TEST_LISTING: '/admin/ielts/LISTENING',
    ADMIN_IETLS_BLOG_WITH_PRACTICE_TEST_ORDERING: '/admin/ielts/[skill]/ordering',

    ADMIN_IELTS_SAMPLE_SEO_LANDING_PAGE_EDIT_PATH:
      '/admin/ielts/sample/seo-info/landing/[templateType]/edit',
    ADMIN_IELTS_SAMPLE_SEO_DETAIL_PAGE_EDIT_PATH:
      '/admin/ielts/sample/seo-info/detail/[sampleId]/edit',

    // IELTS Writing
    ADMIN_IELTS_WRITING_SAMPLE: '/admin/ielts/sample/writing',
    ADMIN_CREATE_IELTS_WRITING_SAMPLE: '/admin/ielts/sample/writing/create',
    ADMIN_DETAIL_IELTS_WRITING_SAMPLE: '/admin/ielts/sample/writing/[id]',
    ADMIN_PREVIEW_IELTS_WRITING_SAMPLE: '/admin/ielts/sample/writing/[id]/preview',
    ADMIN_IELTS_WRITING_BLOG: '/admin/blog-management/writing-blog',
    ADMIN_IELTS_WRITING_SAMPLE_ALL_URLS: '/admin/ielts/sample/writing/all-urls',
    ADMIN_IELTS_WRITING_BLOG_LISTING: '/admin/ielts/blog-listing/writing-blog',
    ADMIN_IELTS_WRITING_SAMPLE_LISTING: '/admin/ielts/sample/writing/listing',
    ADMIN_IELTS_WRITING_SAMPLE_TASK_LISTING: '/admin/ielts/sample/writing/listing/[type]',
    ADMIN_IELTS_WRITING_LISTING: '/admin/ielts/writing/listing',

    // IELTS Speaking
    ADMIN_IELTS_SPEAKING_SAMPLE: '/admin/ielts/sample/speaking',
    ADMIN_CREATE_IELTS_SPEAKING_SAMPLE: '/admin/ielts/sample/speaking/create',
    ADMIN_DETAIL_IELTS_SPEAKING_SAMPLE: '/admin/ielts/sample/speaking/[id]',
    ADMIN_PREVIEW_IELTS_SPEAKING_SAMPLE: '/admin/ielts/sample/speaking/[id]/preview',
    ADMIN_IELTS_SPEAKING_BLOG: '/admin/blog-management/speaking-blog',
    ADMIN_IELTS_SPEAKING_SAMPLE_ALL_URLS: '/admin/ielts/sample/speaking/all-urls',
    ADMIN_IELTS_SPEAKING_BLOG_LISTING: '/admin/ielts/blog-listing/speaking-blog',
    ADMIN_IELTS_SPEAKING_SAMPLE_LISTING: '/admin/ielts/sample/speaking/listing',
    ADMIN_IELTS_SPEAKING_SAMPLE_PART_LISTING: '/admin/ielts/sample/speaking/listing/[type]',
    ADMIN_IELTS_SPEAKING_LISTING: '/admin/ielts/speaking/listing',

    // Chia sẻ kinh nghiệm
    ADMIN_BLOG_MANAGEMENT_BOOK_REVIEW: '/admin/blog-management/book-review',
    ADMIN_BLOG_MANAGEMENT_BOOK_REVIEW_DETAIL: '/admin/blog-management/book-review/[id]',
    ADMIN_BLOG_MANAGEMENT_BOOK_REVIEW_PREVIEW: '/admin/blog-management/book-review/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_BOOK_REVIEW_CREATE: '/admin/blog-management/book-review/create',

    ADMIN_BLOG_MANAGEMENT_CENTER_TEACHER_COURSE_REVIEW:
      '/admin/blog-management/center-teacher-course-review',
    ADMIN_BLOG_MANAGEMENT_CENTER_DETAIL: '/admin/blog-management/center-review/[id]',
    ADMIN_BLOG_MANAGEMENT_CENTER_PREVIEW: '/admin/blog-management/center-review/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_CENTER_CREATE: '/admin/blog-management/center-review/create',

    ADMIN_BLOG_MANAGEMENT_TEACHER_DETAIL: '/admin/blog-management/teacher-review/[id]',
    ADMIN_BLOG_MANAGEMENT_TEACHER_PREVIEW: '/admin/blog-management/teacher-review/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_TEACHER_CREATE: '/admin/blog-management/teacher-review/create',

    ADMIN_BLOG_MANAGEMENT_COURSE_DETAIL: '/admin/blog-management/course-review/[id]',
    ADMIN_BLOG_MANAGEMENT_COURSE_PREVIEW: '/admin/blog-management/course-review/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_COURSE_CREATE: '/admin/blog-management/course-review/create',

    ADMIN_BLOG_MANAGEMENT_IELTS_INFO: '/admin/blog-management/ielts-info',
    ADMIN_BLOG_MANAGEMENT_IELTS_INFO_DETAIL: '/admin/blog-management/ielts-info/[id]',
    ADMIN_BLOG_MANAGEMENT_IELTS_INFO_PREVIEW: '/admin/blog-management/ielts-info/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_IELTS_INFO_CREATE: '/admin/blog-management/ielts-info/create',

    // GRAMMAR BLOG
    ADMIN_BLOG_MANAGEMENT_GRAMMAR: '/admin/blog-management/grammar',
    ADMIN_BLOG_MANAGEMENT_GRAMMAR_DETAIL: '/admin/blog-management/grammar/[id]',
    ADMIN_BLOG_MANAGEMENT_GRAMMAR_PREVIEW: '/admin/blog-management/grammar/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_GRAMMAR_CREATE: '/admin/blog-management/grammar/create',

    // VOCAB
    ADMIN_BLOG_MANAGEMENT_VOCAB: '/admin/blog-management/vocab',
    ADMIN_BLOG_MANAGEMENT_VOCAB_DETAIL: '/admin/blog-management/vocab/[id]',
    ADMIN_BLOG_MANAGEMENT_VOCAB_PREVIEW: '/admin/blog-management/vocab/[id]/preview',
    ADMIN_BLOG_MANAGEMENT_VOCAB_CREATE: '/admin/blog-management/vocab/create',

    // DAILY LEARNING
    ADMIN_DAILY_LEARNING_NEWS: '/admin/daily-learning/news',
    ADMIN_DAILY_LEARNING_NEWS_DETAIL: '/admin/daily-learning/news/[id]',
    ADMIN_DAILY_LEARNING_NEWS_PREVIEW: '/admin/daily-learning/news/[id]/preview',
    ADMIN_DAILY_LEARNING_NEWS_CREATE: '/admin/daily-learning/news/create',

    ADMIN_DAILY_LEARNING_FACTS: '/admin/daily-learning/facts',
    ADMIN_DAILY_LEARNING_RIDDLES: '/admin/daily-learning/riddles',
    ADMIN_DAILY_LEARNING_STORIES: '/admin/daily-learning/stories',
    ADMIN_DAILY_LEARNING_VOCABS: '/admin/daily-learning/vocabs',

    ADMIN_DAILY_LEARNING_MARKTERER_NEWS_AND_LISTING: '/admin/daily-learning/news-and-listing',
    ADMIN_DAILY_LEARNING_MARKTERER_NEWS_EDIT_SEO_INFO:
      '/admin/daily-learning/news-and-listing/seo-info/[id]',
    ADMIN_DAILY_LEARNING_MARKTERER_NEWS_EDIT_LANDING:
      '/admin/daily-learning/news-and-listing/landing',

    // DICTIONARY
    ADMIN_DICTIONARY_MANAGEMENT: '/admin/dictionary-management',

    // K12
    ADMIN_K12_ENGLISH_GRADE_DATA_ENTRY: '/admin/k12/english/grade/[contentGroup]/data-entry',
    ADMIN_K12_ENGLISH_GRADE_DATA_ENTRY_CREATE:
      '/admin/k12/english/grade/[contentGroup]/data-entry/create',
    ADMIN_K12_ENGLISH_GRADE_DATA_ENTRY_DETAIL:
      '/admin/k12/english/grade/[contentGroup]/data-entry/[id]',
    ADMIN_K12_ENGLISH_GRADE_DATA_ENTRY_PREVIEW:
      '/admin/k12/english/grade/[contentGroup]/data-entry/[id]/preview',
    ADMIN_K12_ENGLISH_GRADE_MARKETER: '/admin/k12/english/grade/[contentGroup]/marketer',

    ADMIN_K12_HIGH_SCHOOL_LIST_DATA_ENTRY: '/admin/blog-management/k12-high-school/data-entry',
    ADMIN_K12_HIGH_SCHOOL_CREATE_DATA_ENTRY:
      '/admin/blog-management/k12-high-school/data-entry/create',
    ADMIN_K12_HIGH_SCHOOL_SETUP_DETAIL_DATA_ENTRY:
      '/admin/blog-management/k12-high-school/data-entry/[id]',

    ADMIN_K12_HIGH_SCHOOL_LIST_MARKETER: '/admin/blog-management/k12-high-school/marketer',
    ADMIN_K12_HIGH_SCHOOL_CREATE_MARKETER: '/admin/blog-management/k12-high-school/marketer/create',

    ADMIN_K12_COLLEGE_LIST_DATA_ENTRY: '/admin/blog-management/k12-college/data-entry',
    ADMIN_K12_COLLEGE_CREATE_DATA_ENTRY: '/admin/blog-management/k12-college/data-entry/create',
    ADMIN_K12_COLLEGE_SETUP_DETAIL_DATA_ENTRY: '/admin/blog-management/k12-college/data-entry/[id]',

    ADMIN_K12_COLLEGE_LIST_MARKETER: '/admin/blog-management/k12-college/marketer',
    ADMIN_K12_COLLEGE_CREATE_MARKETER: '/admin/blog-management/k12-college/marketer/create',

    ADMIN_K12_HIGHT_SCHOOL_PREVIEW_ORDER_LANDING_PAGE_EDIT_PATH:
      '/admin/blog-management/k12-high-school/marketer/ordering',

    // Thông tin về DOL

    /* Tin tức */
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_NEWS: '/admin/blog-management/dol-news',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_NEWS_CREATE: '/admin/blog-management/dol-news/create',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_NEWS_DETAIL: '/admin/blog-management/dol-news/[id]',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_NEWS_DETAIL_PREVIEW:
      '/admin/blog-management/dol-news/[id]/preview',

    /* Câu chuyện học viên */
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_STUDENT_STORY: '/admin/blog-management/student-story',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_STUDENT_STORY_CREATE:
      '/admin/blog-management/student-story/create',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_STUDENT_STORY_DETAIL:
      '/admin/blog-management/student-story/[id]',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_STUDENT_STORY_DETAIL_PREVIEW:
      '/admin/blog-management/student-story/[id]/preview',

    /* Linearthinking */
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_LINEAR: '/admin/blog-management/dol-linear',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_LINEAR_CREATE: '/admin/blog-management/dol-linear/create',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_LINEAR_DETAIL: '/admin/blog-management/dol-linear/[id]',
    ADMIN_BLOG_MANAGEMENT_DOL_INFO_LINEAR_DETAIL_PREVIEW:
      '/admin/blog-management/dol-linear/[id]/preview',
  },
  pageManagement: {
    MARKETER_UPDATE_IE_BLOGS: '/admin/ielts/blog-listing/[id]/edit',
    SHARING_BOOK: '/admin/sharing-experience/book',
    SHARING_CENTER: '/admin/sharing-experience/center',
    SHARING_GENERAL: '/admin/sharing-experience/ielts-general',
    SHARING_BOOK_LISTING: '/admin/sharing-experience/listing/book',
    ADMIN_SHARING_BOOK_LISTING: '/admin/sharing-experience/seo-info/book/landing',
    ADMIN_SHARING_CENTER_LISTING: '/admin/sharing-experience/seo-info/center/landing',
    ADMIN_SHARING_FINAL_LISTING: '/admin/sharing-experience/seo-info/final/landing',
    SHARING_CENTER_LISTING: '/admin/sharing-experience/listing/center',
    SHARING_CENTER_LISTING_FINAL: '/admin/sharing-experience/listing/final',
    MARKETER_UPDATE_GRAMMAR_BLOGS: '/admin/maketer-grammar/[id]/edit',
    MARKETER_UPDATE_VOCAB_BLOGS: '/admin/maketer-vocab/[id]/edit',

    MARKETER_UPDATE_BOOK_REIVEW_BLOGS: '/admin/ielts/blog-listing/book/[id]/edit',
    MARKETER_UPDATE_CENTER_REVIEW_BLOGS: '/admin/ielts/blog-listing/teacher/[id]/edit',
    MARKETER_UPDATE_IELTS_INFO_BLOGS: '/admin/ielts/blog-listing/general/[id]/edit',

    // GRAMMAR
    MAKETER_GRAMMAR_LIST: '/admin/maketer-grammar',
    ADMIN_IELTS_GRAMMAR_LISTING: '/admin/ielts/grammar/listing',

    // VOCAB
    MAKETER_VOCAB_LIST: '/admin/maketer-vocab',
    ADMIN_IELTS_VOCAB_LISTING: '/admin/ielts/vocab/listing',

    // DICTATIONARY
    MAKERTER_DICTATIONARY_LISTING: '/admin/dictionary-listing',
    DICTIONAY_EDIT_SEO: '/admin/dictionary-listing/seo-info/[id]',
    DICTIONAY_EDIT_LANDING: '/admin/dictionary-listing/seo-info/',

    // K12
    ADMIN_K12_ENGLISH_GRADE_MARKETER: '/admin/k12/english/grade/[contentGroup]/marketer',
    ADMIN_K12_ENGLISH_GRADE_MARKETER_EDIT_SEO_LANDING:
      '/admin/k12/english/seo-info/landing/[templateType]/edit',
    ADMIN_K12_ENGLISH_GRADE_MARKETER_EDIT_SEO_DETAIL: '/admin/k12/english/seo-info/[id]/edit',
    MARKETER_EDIT_K12_HIGH_SCHOOL_SEO_DETAIL:
      '/admin/blog-management/k12-high-school/marketer/[id]',
    MAKETER_EDIT_K12_college_SEO_DETAIL: '/admin/blog-management/k12-college/marketer/[id]',

    // Thông tin về DOL

    /* Tin tức */
    ADMIN_BLOGS_DOL_INFO_NEWS_LISTING: '/admin/blogs/dol-news/listing',
    ADMIN_BLOGS_DOL_INFO_NEWS_CREATE: '/admin/blogs/dol-news/create',
    ADMIN_BLOGS_DOL_INFO_NEWS_DETAIL_EDIT: '/admin/blogs/dol-news/[id]/edit',

    /* Câu chuyện học viên */
    ADMIN_BLOGS_DOL_INFO_STUDENT_STORY_LISTING: '/admin/blogs/student-story/listing',
    ADMIN_BLOGS_DOL_INFO_STUDENT_STORY_CREATE: '/admin/blogs/student-story/create',
    ADMIN_BLOGS_DOL_INFO_STUDENT_STORY_DETAIL_EDIT: '/admin/blogs/student-story/[id]/edit',

    /* Báo chí nói gì về DOL */
    ADMIN_NEWS_SITE_LISTING: '/admin/news-site/listing',

    /* Linearthinking */
    ADMIN_BLOGS_DOL_INFO_LINEAR_LISTING: '/admin/blogs/dol-linear/listing',
    ADMIN_BLOGS_DOL_INFO_LINEAR_DETAIL_EDIT: '/admin/blogs/dol-linear/[id]/edit',
  },
  landing: {
    ROOT_PATH: environment.SEO_PREFIX,

    STUDENT_DO_TEST_PRACTICE_PATH: (testSectionId) =>
      `/static/practice-test/do-test/${testSectionId}`,
    STUDENT_VIEW_RESULT_PRACTICE_PATH: (testSectionId) =>
      `/static/practice-test/view-result/${testSectionId}`,
    STUDENT_TEST_RESULT_PRACTICE_PATH: (testSectionId) =>
      `/static/practice-test/test-result/${testSectionId}`,
    STUDENT_EXPLANATION_PRACTICE_PATH: (testSectionId) =>
      `/static/practice-test/explanation/${testSectionId}`,
    STUDENT_TEST_HISTORY_PRACTICE_PATH: (testSectionId) =>
      `/static/practice-test/history/${testSectionId}`,
    STUDENT_DICTATION_HISTORY_PATH: (dictationId) => `/static/dictation/history/${dictationId}`,

    STUDENT_START_PRACTICE_TEST_BY_ID: '/static/practice-test/start-test/[testSectionId]',
    STUDENT_EXPLANATION_PRACTICE_TEST_BY_ID: '/static/practice-test/explanation/[testSectionId]',

    STUDENT_START_FULL_TEST_BY_ID: '/static/full-test/start-test/[testId]',
    STUDENT_DO_FULL_TEST_BY_USER_TEST_ID: '/static/full-test/do-test/[userTestId]',
    STUDENT_EXPLANATION_FULL_TEST_BY_ID: '/static/full-test/explanation/[testId]',
    STUDENT_DO_TEST_FULL_PATH: (testId) => `/static/full-test/do-test/${testId}`,
    STUDENT_VIEW_RESULT_FULL_PATH: (testId) => `/static/full-test/view-result/${testId}`,
    STUDENT_TEST_RESULT_FULL_PATH: (testId) => `/static/full-test/test-result/${testId}`,
    STUDENT_EXPLANATION_FULL_PATH: (testId) => `/static/full-test/explanation/${testId}`,
    STUDENT_TEST_HISTORY_FULL_PATH: (testId) => `/static/full-test/history/${testId}`,

    STUDENT_VIEW_STANDARD_TEST_RESULT_FULL_PATH: (testId) =>
      `/static/standard-test/view-result-sumarize/${testId}`,
    STUDENT_VIEW_STANDARD_TEST_RESULT_EXP_FULL_PATH: (testId) =>
      `/static/standard-test/view-result-detail/${testId}`,
    SEARCH_PATH: '/static/search',

    STUDENT_VIEW_MY_ACCOUNT: '/static/my-account',
    STUDENT_VIEW_PREMIUM_REMAINING: '/static/thoi-han-su-dung',
    STUDENT_VIEW_MY_REWARDS: '/static/my-rewards',
    STUDENT_VIEW_MY_PROFILE: '/static/my-profile',
    DICTATION_LANDING: '/ielts/dictation',

    REFERRED: '/static/referred',
  },
  exercise: {
    ADMIN_PATH: '/course/exercise',
    ADMIN_CREATE_PATH: '/course/exercise/create',
    ADMIN_CREATE_BLOGS_PATH: '/course-data-entry/materials/blogs/create',
    ADMIN_EDIT_PATH: '/course/exercise/[id]',
    EXERCISE_ROUTING: '/exercise/routing',

    // Do Exercise
    PREVIEW_EXERCISE: '/exercise/[exerciseId]/preview',

    // User Exercise
    DO_USER_EXERCISE: '/user-exercise/[exerciseId]',
    DO_USER_EXERCISE_START_OVER: '/user-exercise/[exerciseId]/start-over',
    USE_EXERCISE_REVIEW: '/user-exercise/[exerciseId]/review',
    USE_EXERCISE_PREVIEW: '/user-exercise/[exerciseId]/preview',
    USE_EXERCISE_PREVIEW_ATTEMPT: '/user-exercise/[exerciseId]/preview-attempt',
    USE_EXERCISE_MY_ATTEMPT: '/user-exercise/[exerciseId]/my-attempt',

    BLOGS: '/course-data-entry/materials/blogs',
    BLOGS_UPDATE_PATH: '/course-data-entry/materials/blogs/[id]',
    BLOGS_PREVIEW_PATH: '/course-data-entry/materials/blogs/[id]/preview',
  },
  lms: {
    FINAL_TEST_TEACHER_DETAIL_STATISTIC: '/teacher/final-test/[teacherId]/statistic',
    PREVIEW_SAMPLE_BLOG: '/admin/sample-blog/[sampleId]',
    PREVIEW_DICTATION: '/dictation/preview/[dictationId]',
    PREVIEW_VOCAB_BLOG: '/admin/vocab-blog/[vocabId]',
    PREVIEW_ONLINE_TEST: '/admin/online-test/[id]/preview',
    PREVIEW_READNG_SECTION: '/admin/reading-test-sections/[id]/preview',
    PREVIEW_READING_SECTION: '/admin/reading-test-sections/[id]/preview',
    PREVIEW_LISTENING_SECTION: '/admin/listening-test-sections/[id]/preview',
    PREVIEW_MATERIAL_VIDEO: '/admin/materials/video/[materialId]/preview',
    PREVIEW_MATERIAL_PDF: '/admin/materials/pdf/[materialId]/preview',
    PREVIEW_MATERIAL_BLOG: '/admin/materials/blog/[materialId]/preview',
    // for admin
    ADMIN_LIST_WRITING_ASSIGMENT: '/admin/assignment/writing',
    ADMIN_CREATE_WRITING_ASSIGMENT: '/admin/create-writing-assignment',
    ADMIN_EDIT_WRITING_ASSINGMENT_PATH: '/admin/update-writing-assignment/[id]',
    ADMIN_WRITING_ASSIGMENT_HISTORY: '/admin/history-assignment/[revisionId]/writing',

    ADMIN_LIST_SPEAKING_ASSIGMENT: '/admin/assignment/speaking',
    ADMIN_CREATE_SPEAKING_ASSIGMENT: '/admin/create-speaking-assignment',
    ADMIN_EDIT_SPEAKING_ASSINGMENT_PATH: '/admin/update-speaking-assignment/[id]',
    ADMIN_SPEAKING_ASSIGMENT_HISTORY: '/admin/history-assignment/[revisionId]/speaking',

    ADMIN_LIST_EXTRA_DOCS_ASSIGMENT: '/admin/assignment/extra-docs',
    ADMIN_CREATE_EXTRA_DOCS_ASSIGMENT: '/admin/create-extra-docs-assignment',
    ADMIN_EDIT_EXTRA_DOCS_ASSINGMENT: '/admin/update-extra-docs-assignment/[id]',

    // for ta
    TA_ASSIGNMENT_OVERVIEW: '/ta/assignment/overview',
    TA_LIST_WAITING_FOR_RESUBMITTED: '/ta/assignment/waiting-for-resubmitted',
    TA_LIST_ALL_ASSIGNMENT: '/ta/assignment/all',
    TA_LIST_WAITING_FOR_MARK_ASSIGNMENT: '/ta/assignment/waiting-for-mark',
    TA_LIST_MARKED_ASSIGNMENT: '/ta/assignment/marked',
    TA_LIST_REMOVED_ASSIGNMENT: '/ta/assignment/removed',
    TA_LIST_MY_MARKING_ASSIGNMENT: '/ta/assignment/me/marking-assignment',
    TA_LIST_APPROVED_ASSIGNMENT: '/ta/assignment/approved',
    TA_MY_ASSIGNMENT_STATISTIC: '/ta/assignment/me/statistic',
    ALL_TA_MANAGEMENT: '/ta/assignment/ta-management',
    DETAIL_TA_MANAGEMENT: '/ta/assignment/ta-management/[taUserId]',

    // for teacher
    TEACHER_ASSIGNMENT_OVERVIEW: '/assignment-management/assignment/overview',
    TEACHER_MY_COURSE_REMOVED_ASSIGNMENT: '/teacher/assignment/my-course/removed',
    TEACHER_MY_COURSE_WAITING_FOR_RESUBMITTED_ASSIGNMENT:
      '/teacher/assignment/my-course/waiting-for-resubmitted',
    TEACHER_LIST_ALL_ASSIGNMENT: '/teacher/assignment/all',
    TEACHER_MANAGE_ASSIGNMENT_TA: '/teacher/assignment/manage-ta',
    TEACHER_MANAGE_ASSIGNMENT_TA_DETAIL: '/teacher/assignment/manage-ta/[taUserId]',
    TEACHER_MANAGE_ASSIGNMENT_TEACHER: '/teacher/assignment/manage-teacher',
    TEACHER_MANAGE_ASSIGNMENT_TEACHER_DETAIL: '/teacher/assignment/manage-teacher/[teacherId]',
    TEACHER_MANAGE_ASSIGNMENT_BY_COURSE: '/teacher/assignment/manage-by-course',
    TEACHER_MANAGE_ASSIGNMENT_BY_COURSE_DETAIL: '/teacher/assignment/manage-by-course/[courseId]',

    TEACHER_MY_COURSE_WAITING_FOR_MARK_ASSIGNMENT: '/teacher/assignment/my-course/waiting-for-mark',
    TEACHER_MY_COURSE_ASSIGNMENT_IN_MARKING_BY_TA: '/teacher/assignment/my-course/in-marking-by-ta',
    TEACHER_MY_COURSE_WAITING_FOR_APPROVED_TA_ASSIGNMENT:
      '/teacher/assignment/my-course/waiting-for-approve',
    TEACHER_MY_APPROVED_ASSIGNMENT: '/teacher/assignment/me/approved',
    TEACHER_MY_MARKING_ASSIGNMENT: '/teacher/assignment/me/marking',
    TEACHER_MY_MARKED_ASSIGNMENT: '/teacher/assignment/me/marked',

    FINAL_TEST_DO_INPUT_VIEW: '/admin/final-test/online/[id]/submit-user-test',

    // for offline course
    CENTER_MANAGER_OFFLINE_COURSE_LISTING: '/center-manager/offline-course',
    CENTER_MANAGER_OFFLINE_COURSE_DETAIL: '/center-manager/offline-course/[id]',
    ADMIN_CM_OFFLINE_COURSE_GENERAL_INFO:
      '/admin/center-manager/offline-course/courses/[courseId]/general-info',
    ADMIN_CM_OFFLINE_COURSE_OVERVIEW:
      '/admin/center-manager/offline-course/courses/[courseId]/overview',
    ADMIN_CM_OFFLINE_COURSE_TEACHER:
      '/admin/center-manager/offline-course/courses/[courseId]/teacher',
    ADMIN_CM_OFFLINE_COURSE_TA: '/admin/center-manager/offline-course/courses/[courseId]/ta',
    ADMIN_CM_OFFLINE_COURSE_STUDENT:
      '/admin/center-manager/offline-course/courses/[courseId]/student',
    ADMIN_CM_OFFLINE_COURSE_SESSION:
      '/admin/center-manager/offline-course/courses/[courseId]/session',
    ADMIN_CM_OFFLINE_COURSE_ANNOUNCEMENT:
      '/admin/center-manager/offline-course/courses/[courseId]/announcement',

    CENTER_DIRECTOR_CREATE_COURSE: '/center-director/create-offline-course',
    CENTER_DIRECTOR_EDIT_COURSE: '/center-director/edit-course/[id]',
    // Syllabus
    PD_SYLLABUS_MANA_ALL: '/admin/syllabus/all',
    PD_SYLLABUS_SHARED_RESOURCES: '/admin/syllabus/shared-resources',
    PD_SYLLABUS_DETAIL: '/admin/syllabus/detail/[id]',
    CENTER_DIRECTOR_SYLLABUS_MANA_ALL: '/center-director/syllabus/list',

    CENTER_DIRECTOR_SYLLABUS_DETAIL: '/center-director/syllabus/[id]',

    CENTER_MANAGER_SYLLABUS_DETAIL: '/center-manager/syllabus/[id]',

    // Syllabus code

    PD_SYLLABUS_CODE_ALL: '/admin/syllabus-code/listing',

    // teacher
    TEACHER_IN_PROGRESS_CLASS: '/teacher/offline-course-management/my-inprogress-class',
    TEACHER_UPCOMING_CLASS: '/teacher/offline-course-management/my-upcoming-class',
    TEACHER_COMPLETED_CLASS: '/teacher/offline-course-management/my-completed-class',
    TEACHER_SUBSTITUTE_CLASS: '/teacher/offline-course-management/my-substitute-class',
    TEACHER_MY_CLASS_ROADMAP: '/teacher/offline-course-management/my-class/[courseId]/roadmap',
    TEACHER_MY_CLASS_ROADMAP_STUDENT_DETAIL:
      '/teacher/offline-course-management/my-class/[courseId]/roadmap/view-student-detail/[studentId]',
    TEACHER_MY_CLASS_ROADMAP_STUDENT_ROADMAP_PROGRESS:
      '/teacher/offline-course-management/my-class/[courseId]/roadmap/view-student-detail/[studentId]/progress/[progressId]',
    TEACHER_MY_CLASS_VIEW_STUDENT_VOCAB_STATISTIC:
      '/vocab/view-student-stats/[courseVocabSetId]/statistics/[studentRegistrationKey]',
    TEACHER_MY_CLASS_OVERVIEW: '/teacher/offline-course-management/my-class/[courseId]/overview',
    TEACHER_MY_CLASS_FINAL_TEST:
      '/teacher/offline-course-management/my-class/[courseId]/final-test',
    TEACHER_MY_SUBSTITUTE_CLASS_OVERVIEW:
      '/teacher/offline-course-management/my-substitute-class/[courseId]/overview',
    TEACHER_MY_SUBSTITUTE_CLASS_SYLLABUS:
      '/teacher/offline-course-management/my-substitute-class/[courseId]/syllabus',
    TEACHER_MY_CLASS_STUDENT_LIST:
      '/teacher/offline-course-management/my-class/[courseId]/student-list',
    TEACHER_MY_CLASS_STUDENT_DETAIL:
      '/teacher/offline-course-management/my-class/[courseId]/student-detail/[userId]',
    TEAHCER_MY_CLASS_ANNOUNCEMENT:
      '/teacher/offline-course-management/my-class/[courseId]/announcement',
    TEACHER_MY_CLASS_COURSE_INFO:
      '/teacher/offline-course-management/my-class/[courseId]/course-info',
    TEACHER_MY_CLASS_SYLLABUS:
      '/teacher/offline-course-management/my-class/[courseId]/syllabus/[weekKey]',
    TEACHER_MY_SUBSTITUTE_CLASS_COURSE_INFO:
      '/teacher/offline-course-management/my-substitute-class/[courseId]/course-info',
    TEACHER_MY_CLASS_STUDENT_STATISTIC:
      '/teacher/offline-course-management/my-class/[courseId]/student-statistic',
    TEACHER_MY_CLASS_STUDENT_STATISTIC_DETAIL:
      '/teacher/offline-course-management/my-class/[courseId]/student-statistic/[studentId]/view',
    TEACHER_MY_CLASS_SESSION: '/teacher/offline-course-management/my-class/[courseId]/session',
    TEACHER_MY_CLASS_ONLINE_TEST_VIEW_STATS:
      '/teacher/offline-course-management/my-class/[courseId]/online-test',
    TEACHER_MY_CLASS_SAT_TEST: '/teacher/offline-course-management/my-class/[courseId]/sat-test',
    TEACHER_MY_CLASS_SAT_TEST_DETAIL:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]',
    TEACHER_MY_CLASS_SAT_TEST_DETAIL_VIEW_STATISTIC:
      '/course-management/courses/[courseId]/detail/sat-test/[courseSatTestId]/view-statistic',
    TEACHER_MY_CLASS_SAT_TEST_PREVIEW_MODULE_STATISTIC:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]/module-test/preview',
    TEACHER_MY_CLASS_SAT_TEST_PREVIEW_SECTION_TEST_STATISTIC:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]/section-test/preview',
    TEACHER_MY_CLASS_SAT_TEST_PREVIEW_FULL_TEST_STATISTIC:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]/full-test/preview',
    TEACHER_MY_CLASS_SAT_TEST_PROGRESS_OVERVIEW:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]/[userCourseSatTestId]/overview',
    TEACHER_MY_CLASS_SAT_TEST_PROGRESS_DETAIL:
      '/teacher/offline-course-management/my-class/[courseId]/sat-test/[courseSatTestId]/[userCourseSatTestId]/detail',

    CENTER_MANAGER_SYLLABUS_MANA_ALL: '/center-manager/syllabus/list',
    COURSE_ASSIGNMENT: '/course-assignment/[courseAssignmentId]',

    // Roadmap
    ADMIN_DATA_ENTRY_ROADMAP_LISTING: '/course-data-entry/roadmap/listing',
    COURSE_DATA_ENTRY_ROADMAP_CREATE: '/course-data-entry/roadmap/create',
    COURSE_DATA_ENTRY_ROADMAP_EDIT: '/course-data-entry/roadmap/[roadmapId]/edit',
    COURSE_DATA_ENTRY_ROADMAP_PREVIEW: '/course-data-entry/roadmap/[roadmapId]/preview',
    COURSE_DATA_ENTRY_ROADMAP_HISTORY: '/course-data-entry/roadmap/document-history/[revisionId]',

    // Exercise V2
    COURSE_DATA_ENTRY_EXERCISE_V2_LISTING: '/course-data-entry/exercise-v2/listing',
    COURSE_DATA_ENTRY_EXERCISE_V2_CREATE: '/course-data-entry/exercise-v2/create',
    COURSE_DATA_ENTRY_EXERCISE_V2_EDIT: '/course-data-entry/exercise-v2/[exerciseId]/edit',
    COURSE_DATA_ENTRY_EXERCISE_V2_HISTORY:
      '/course-data-entry/exercise-v2/document-history/[revisionId]',

    COURSE_DATA_ENTRY_EXERCISE_V2_PREVIEW: '/course-data-entry/exercise-v2/[exerciseId]/preview',
    TEACHER_EXERCISE_V2_PREVIEW: '/teacher/exercise-v2/[exerciseId]/preview',

    // Syllabus code
    ADMIN_SYLLABUS_CODE_LISTING: '/admin/syllabus-code/listing',
    ADMIN_SYLLABUS_CODE_CREATE: '/admin/syllabus-code/create',
    ADMIN_SYLLABUS_CODE_EDIT: '/admin/syllabus-code/[syllabusCodeId]/edit',
  },
  entranceTest: {
    // PUBLIC RESULT
    ENTRANCE_TEST_STUDENT_VIEW_PUBLIC_RESULT: '/user-test/result/[userEntranceTestId]',

    // TEST
    ENTRANCE_TEST_MANAGEMENT_ALL_TEST_LISTING: '/course-data-entry/tests',
    ENTRANCE_TEST_MANAGEMENT_CREATE_TEST: '/course-data-entry/tests/create',
    ENTRANCE_TEST_MANAGEMENT_UPDATE_TEST: '/course-data-entry/tests/[testId]',
    ENTRANCE_TEST_MANAGEMENT_PREVIEW_TEST: '/course-data-entry/tests/[testId]/preview',
    ENTRANCE_TEST_MANAGEMENT_HISTORY: '/course-data-entry/tests/history/[revisionId]',

    // MC
    // MARKING FORM
    ENTRANCE_TEST_MANAGEMENT_ALL_MARKING_FORM_LISTING: '/course-data-entry/marking-form',
    ENTRANCE_TEST_MANAGEMENT_MARKING_FORM_CREATE: '/course-data-entry/marking-form/create',
    ENTRANCE_TEST_MANAGEMENT_MARKING_FORM_EDIT:
      '/course-data-entry/marking-form/[markingFormId]/edit',

    ENGINEER_ENTRANCE_TEST_REPORT_LIST: '/engineer/test-report',

    ADMIN_MY_CONTACT: '/admin/my-contact',
    ADMIN_ALL_CONTACTS: '/admin/all-contacts',
    ADMIN_NOT_STARTED_YET: '/admin/not-started-yet',
    ADMIN_IN_PROGRESS: '/admin/in-progress',
    ADMIN_SUBMITTED: '/admin/submitted',
    ADMIN_IN_MARKING: '/admin/in-marking',
    ADMIN_MARKED: '/admin/marked',
    ADMIN_WAITING_FOR_REMARK: '/admin/waiting-for-remark',
    ADMIN_CONSULTED: '/admin/consulted',
    ADMIN_EXPIRED: '/admin/expired',
    ADMIN_CREATE_ENTRANCE_TEST: '/admin/entrance-test/create',
    ADMIN_ENTRANCE_TEST_DETAIL_OFFLINE: '/entrance-test/[id]/view-detail',
    ADMIN_ENTRANCE_TEST_DETAIL_ONLINE: '/entrance-test/[id]/view-detail',

    SUBMIT_USER_TEST: '/entrance-test/[id]/view-detail',
    OFFLINE_TEST_SUMMARIZE: '/test-marker/offline-test/[id]/summarize',
    ONLINE_TEST_SUMMARIZE: '/test-marker/online-test/[id]/summarize',
    OFFLINE_TEST_MARKING: '/test-marker/entrance-test/[id]/marking',
    ONLINE_TEST_MARKING: '/test-marker/entrance-test/[id]/marking',

    PREVIEW_OFFLINE_USER_ENTRANCE_TEST_READING:
      '/offline-test/entrance/user-test/[userEntranceTestId]/reading/preview',
    PREVIEW_OFFLINE_USER_ENTRANCE_TEST_LISTENING:
      '/offline-test/entrance/user-test/[userEntranceTestId]/listening/preview',
    PREVIEW_ONLINE_USER_ENTRANCE_TEST_READING:
      '/online-test/entrance/user-test/[userEntranceTestId]/reading/preview',
    PREVIEW_ONLINE_USER_ENTRANCE_TEST_LISTENING:
      '/online-test/entrance/user-test/[userEntranceTestId]/listening/preview',
    VIEW_DETAIL_SAT_ENTRANCE_TEST: '/online-test/entrance/sat/[userEntranceTestId]/detail',

    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_SKILL_PART_USER:
      '/course-data-entry/preview/entrance-test/[mode]/[skillType]/[entranceTestId]/user-part',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_READING_PART:
      '/course-data-entry/preview/[resourceId]/reading/[testType]/part',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_LISTENING_PART:
      '/course-data-entry/preview/[resourceId]/listening/[testType]/part',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_WRITING_PART:
      '/course-data-entry/preview/[resourceId]/writing/part',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_SPEAKING_PART:
      '/course-data-entry/preview/[resourceId]/speaking/part',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_SKILL_FULL:
      '/course-data-entry/preview/full/[entranceTestId]',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_SKILL_FULL_USER_OFFLINE:
      '/course-data-entry/preview/full/user/offline/[entranceTestId]',
    COURSE_DATA_ENTRY_PREVIEW_ENTRANCE_TEST_SKILL_FULL_USER_ONLINE:
      '/course-data-entry/preview/full/user/online/[entranceTestId]',
    FINAL_TEST_MANAGEMENT_ALL_TEST_LISTING: '',
    ADMIN_DETAIL_CONTACT: '/admin/all-contacts/[id]',
    ADMIN_ALL_TESTS: '/admin/all-tests',
    TEACHER_ENTRANCE_TEST_EC_OVERVIEW: '/admin/ec-summarize',
    TEACHER_ENTRANCE_TEST_OVERVIEW: '/admin/overall',
    TEACHER_ENTRANCE_TEST_EC_OVERVIEW_DETAIL: '/admin/ec-summarize/[id]',
    ADMIN_LIST_ALL_ASSIGNMENT_BLANK: '/admin/missing',
    ENTRANCE_TEST_FROM_OLD_SYSTEM: '/admin/from-old-system',
  },
  multipleChoice: {
    // TEST
    ALL_QUIZ: '/course-data-entry/multiple-choice',
    CREATE_QUIZ: '/course-data-entry/multiple-choice/create',
    EDIT_QUIZ: '/course-data-entry/multiple-choice/[id]/setup',
    PREVIEW: '/course-data-entry/multiple-choice/[id]/preview',
  },
  entranceTestMarked: {
    ADMIN_WTF_TEST: '/test-marker/waiting-for-mark',
    ADMIN_WTFR_TEST: '/test-marker/waiting-for-re-mark',
    ADMIN_MARKED: '/test-marker/marked',
    ADMIN_MARKING: '/test-marker/marking',
    ADMIN_WAITING_FOR_FEEDBACK: '/test-marker/waiting-for-feedback',
    ADMIN_FEEDBACK: '/test-marker/feedbacked',
    FEED_BACK_OFFLINE_TEST: '/test-marker/offline-test/[id]/quick-feed-back',
    FEED_BACKED_OFFLINE_TEST: '/test-marker/offline-test/[id]/feed-backed',
    ADMIN_IN_MARKING_TEST: '/test-marker/in-marking',
    ADMIN_VIEW_IN_MARKING_TEST_HISTORY: '/test-marker/entrance-test/[id]/marking-history',
    ALL_TEST: '/test-marker/all',
    ADMIN_ENTRANCE_TEST_DETAIL_OFFLINE: '/test-marker/entrance-test/[id]/view-detail',
    ADMIN_ENTRANCE_TEST_DETAIL_ONLINE: '/test-marker/entrance-test/[id]/view-detail',
    TM_ENTRANCE_TEST_MISSING_DETAIL_ONLINE: '/test-marker/entrance-test/[id]/view-detail',
    ADMIN_ENTRANCE_TEST_MISSING_DETAIL_ONLINE: '/test-marker/entrance-test/[id]/view-detail',
    TA_LIST_ALL_ASSIGNMENT_BLANK: '/test-marker/blank',
    TEACHER_ENTRANCE_TEST_TA_TM_OVERVIEW: '/test-marker/ta-summarize',
    TA_LIST_SYSTEM_REMOVE_ASSIGNMENT: '/test-marker/remove-by-system',
    TEACHER_ENTRANCE_TEST_TA_OVERVIEW: '/test-marker/overall',
    TEACHER_ENTRANCE_TEST_TA_OVERVIEW_DETAIL: '/test-marker/overall/detail',
  },
  doSATEntranceTest: {
    ROOT_PATH: environment.REACT_APP_STUDENT_ENTRANCE_TEST_SAT_ROOT_PATH,
    START_ENTRANCE_TEST: '/do-test/[userEntranceTestId]/start',
    ENTRANCE_TEST_LOGIN: '/do-test/[userEntranceTestId]',

    ENTRANCE_TEST_RESULT: '/do-test/[userEntranceTestId]/result',
    ENTRANCE_TEST_DETAIL: '/do-test/[userEntranceTestId]/detail',
  },
  doEntranceTest: {
    ROOT_PATH: environment.REACT_APP_STUDENT_ENTRANCE_TEST_ROOT_PATH,
    START_ENTRANCE_TEST: '/user-test/[userEntranceTestId]/start',
    ENTRANCE_TEST_LOGIN: '/user-test/[userEntranceTestId]',

    DO_ENTRANCE_TEST_DETAIL: '/entrance-test/[id]/detail',
  },
  centerDirector: {
    OFFLINE_COURSE_LISTING: '/center-director/offline-course',
    OFFLINE_COURSE_DETAIL_GENERAL_INFO: '/center-director/offline-course/[courseId]/general-info',
    OFFLINE_COURSE_DETAIL_TEACHER: '/center-director/offline-course/[courseId]/teacher-info',
    OFFLINE_COURSE_DETAIL_TA: '/center-director/offline-course/[courseId]/ta-info',
    OFFLINE_COURSE_DETAIL_STUDENT: '/center-director/offline-course/[courseId]/student-info',
    OFFLINE_COURSE_DETAIL_OVERVIEW: '/center-director/offline-course/[courseId]/overview-info',
    SYLLABUS_VIEW_DETAIL: '/center-director/syllabus/[id]',

    OFFLINE_COURSE_VIEW_STUDENT_DETAIL: '/center-director/offline-course/student/[studentId]',
    OFFLINE_COURSE_VIEW_STUDENT_ACTIVITY_LOG:
      '/center-director/offline-course/student/[studentId]/activity-log',

    OFFLINE_COURSE_STUDENT_LISTING: '/center-director/offline-course/students',
  },
  centerManager: {
    OFFLINE_COURSE_LISTING: '/center-manager/offline-course',
    OFFLINE_COURSE_DETAIL_GENERAL_INFO: '/center-manager/offline-course/[courseId]/general-info',
    OFFLINE_COURSE_DETAIL_TEACHER: '/center-manager/offline-course/[courseId]/teacher-info',
    OFFLINE_COURSE_DETAIL_TA: '/center-manager/offline-course/[courseId]/ta-info',
    OFFLINE_COURSE_DETAIL_STUDENT: '/center-manager/offline-course/[courseId]/student-info',
    OFFLINE_COURSE_DETAIL_OVERVIEW: '/center-manager/offline-course/[courseId]/overview-info',
    OFFLINE_COURSE_DETAIL_ANNOUNCEMENT:
      '/center-manager/offline-course/[courseId]/announcement-info',
    OFFLINE_COURSE_DETAIL_SESSION: '/center-manager/offline-course/[courseId]/session-info',
    SYLLABUS_VIEW_DETAIL: '/center-manager/syllabus/[id]',
    OFFLINE_COURSE_STUDENT_LISTING: '/center-manager/offline-course/students',

    OFFLINE_COURSE_VIEW_STUDENT_DETAIL: '/center-manager/offline-course/student/[studentId]',
    OFFLINE_COURSE_VIEW_STUDENT_ACTIVITY_LOG:
      '/center-manager/offline-course/student/[studentId]/activity-log',
  },
  assignment: {
    // TA assignment correction
    TA_ASSIGNMENT_CORRECTION: '/assignment/ta/correction/[correctionId]',
    TA_ASSIGNMENT_AI_CORRECTION: '/assignment/ai-correction/[correctionId]',

    // Teacher assignment correction
    TEACHER_ASSIGNMENT_CORRECTION: '/assignment/teacher/correction/[correctionId]',
    TEACHER_ASSIGNMENT_CORRECTION_VIEW_TA: '/assignment/teacher/correction/[correctionId]/view-ta',

    PREVIEW_ASSIGNMENT: '/assignment/[assignmentId]/preview',
  },
  university: {
    ADMIN_CREATE_QUESTION: '/admin/university/create-question',
    ADMIN_LIST_QUESTION: '/admin/university/list-question',
    ADMIN_EDIT_QUESTION: '/admin/university/edit-question/[id]',

    ADMIN_LIST_TEST: '/admin/university/list-test',
    ADMIN_CREATE_TEST: '/admin/university/create-test',
    ADMIN_EDIT_TEST: '/admin/university/edit-test/[id]',
    MARKERTER_EDIT_TEST: '/admin/university/makerter-edit-test/[id]',
    LIST_TEST_SEO: '/admin/university/makerter-list-seo',
    ADMIN_SEO_TEST_DETAIL_PAGE_EDIT_PATH:
      '/admin/university/makerter-list-seo/[templateType]/[testType]/[id]/edit',
    ADMIN_STANDARD_TEST_SEO_INFO_LANDING: '/admin/university/listing',
    ADMIN_STANDARD_TEST_PREVIEW_ORDERING: '/admin/university/order',
  },
  lmsStudent: {
    // Main page
    MY_CLASSES: '/my-classes',
    MY_CLASS_ASSIGNMENT: '/my-classes/[courseId]/assignment',
    MY_CLASS_OVERVIEW: '/my-classes/[courseId]/overview',
    MY_CLASS_ROLLING_OVERVIEW: '/my-classes/[courseId]/rolling-overview',
    MY_CLASS_SYLLABUS: '/my-classes/[courseId]/syllabus/[weekKey]',
    MY_CLASS_ANNOUNCEMENT: '/my-classes/[courseId]/announcement',
    MY_CLASS_ANNOUNCEMENT_DETAIL: '/my-classes/[courseId]/announcement/[announcementId]',
    MY_CLASS_COURSE_INFO: '/my-classes/[courseId]/course-info',
    MY_CLASS_ATTENDANCE: '/my-classes/[courseId]/attendance',
    MY_CLASS_FINAL_TEST: '/my-classes/[courseId]/final-test',
    MY_CLASS_SAT_TEST: '/my-classes/[courseId]/sat-test',
    MY_CLASS_DICTATION: '/my-classes/[courseId]/dictation',
    MY_CLASS_MEMORIES: '/my-classes/[courseId]/memories',
    MY_CLASS_CERTIFICATION: '/my-classes/[courseId]/certification',

    SAT_TEST: {
      START: '/my-classes/[courseId]/sat-test/tests/[id]/start',
      PROGRESS: {
        DO_TEST: '/my-classes/[courseId]/sat-test/progress/[id]',
        RESULT: '/my-classes/[courseId]/sat-test/progress/[id]/result',
      },
    },

    STUDENT_COURSE_ASSIGNMENT_PREVIEW:
      '/my-classes/[courseId]/course-assignment/[courseAssignmentId]/preview',
    STUDENT_COURSE_ASSIGNMENT_START:
      '/my-classes/[courseId]/course-assignment/[courseAssignmentId]/start',
    STUDENT_COURSE_ASSIGNMENT_REVIEW:
      '/my-classes/[courseId]/course-assignment/[courseAssignmentId]/review',
    STUDENT_COURSE_ASSIGNMENT_AI_CORRECTION:
      '/my-classes/[courseId]/course-assignment/[courseAssignmentId]/ai-correction',
    START_ASSIGNMENT: '/my-classes/[courseId]/assignment/[assignmentId]/start',
    PREVIEW_ASSIGNMENT: '/my-classes/[courseId]/assignment/[assignmentId]/preview',
    PREVIEW_CORRECTION: '/my-classes/[courseId]/assignment/progress/[progressId]/preview',
    MY_CLASS_VOCAB: '/my-classes/[courseId]/vocab',
    MY_CLASS_VOCAB_DETAIL: '/my-classes/[courseId]/vocab/[courseVocabSetId]',

    // Learning Vocab
    STUDENT_VOCAB_TEST_START: '/my-classes/[courseId]/learning-vocab/test/[progressId]/start',
    STUDENT_VOCAB_TEST: '/my-classes/[courseId]/learning-vocab/test/[progressId]',
    STUDENT_VOCAB_TEST_START_NAVIGATION:
      '/my-classes/[courseId]/learning-vocab/test/start-navigation',
    STUDENT_VOCAB_TEST_RESULT: '/my-classes/[courseId]/learning-vocab/test/[progressId]/result',
    STUDENT_VOCAB_LEARN_START: '/my-classes/[courseId]/learning-vocab/learn/[progressId]/start',
    STUDENT_VOCAB_LEARN_START_NAVIGATION:
      '/my-classes/[courseId]/learning-vocab/learn/start-navigation',
    STUDENT_VOCAB_LEARN: '/my-classes/[courseId]/learning-vocab/learn/[progressId]',
    STUDENT_VOCAB_LEARN_RESULT: '/my-classes/[courseId]/learning-vocab/learn/[progressId]/result',
    STUDENT_VOCAB_FLASHCARD: '/my-classes/[courseId]/learning-vocab/flashcard/[courseVocabSetId]',
    STUDENT_VOCAB_FLASHCARD_RESULT:
      '/my-classes/[courseId]/learning-vocab/flashcard/[courseVocabSetId]/result',
    STUDENT_VOCAB_MATCHING_START:
      '/my-classes/[courseId]/learning-vocab/matching/[progressId]/start',
    STUDENT_VOCAB_MATCHING_START_NAVIGATION:
      '/my-classes/[courseId]/learning-vocab/matching/start-navigation',
    STUDENT_VOCAB_MATCHING: '/my-classes/[courseId]/learning-vocab/matching/[progressId]',
    STUDENT_VOCAB_REVIEW_START: '/my-classes/[courseId]/learning-vocab/review/[progressId]/start',
    STUDENT_VOCAB_REVIEW: '/my-classes/[courseId]/learning-vocab/review/[progressId]',
    STUDENT_VOCAB_REVIEW_RESULT: '/my-classes/[courseId]/learning-vocab/review/[progressId]/result',
    STUDENT_VOCAB_WRITE_SENTENCE:
      '/my-classes/[courseId]/learning-vocab/write-sentence/[courseVocabSetId]',
    STUDENT_VOCAB_WRITE_SENTENCE_START:
      '/my-classes/[courseId]/learning-vocab/write-sentence/[courseVocabSetId]/start',
    STUDENT_VOCAB_WRITE_SENTENCE_START_REMAINING:
      '/my-classes/[courseId]/learning-vocab/write-sentence/[courseVocabSetId]/start-remaining',
    STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS:
      '/my-classes/[courseId]/learning-vocab/write-sentence/progress/[progressId]',
    STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS_RESULT:
      '/my-classes/[courseId]/learning-vocab/write-sentence/progress/[progressId]/result',
    STUDENT_VOCAB_PRONUNCIATION:
      '/my-classes/[courseId]/learning-vocab/pronunciation/[courseVocabSetId]',
    STUDENT_VOCAB_PRONUNCIATION_START:
      '/my-classes/[courseId]/learning-vocab/pronunciation/[courseVocabSetId]/start',
    STUDENT_VOCAB_PRONUNCIATION_START_REMAINING:
      '/my-classes/[courseId]/learning-vocab/pronunciation/[courseVocabSetId]/start-remaining',
    STUDENT_VOCAB_PRONUNCIATION_PROGRESS:
      '/my-classes/[courseId]/learning-vocab/pronunciation/progress/[progressId]',
    STUDENT_VOCAB_PRONUNCIATION_PROGRESS_RESULT:
      '/my-classes/[courseId]/learning-vocab/pronunciation/progress/[progressId]/result',

    // Standard Attendance Summary
    STUDENT_STANDARD_ATTENDANCE_SUMMARY:
      '/my-classes/[courseId]/standard-attendance-summary?syllabusCode=[syllabusCode]&sessionIndex=[sessionIndex]',
  },
  lmsStudentTrial: {
    LANDING_PAGE: '/free-trial/onboarding',
    // Main page
    MY_CLASSES: '/my-classes-trial',
    MY_CLASS_ASSIGNMENT: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/assignment',
    MY_CLASS_OVERVIEW: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/overview',
    MY_CLASS_ROLLING_OVERVIEW:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/rolling-overview',
    MY_CLASS_SYLLABUS: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/syllabus/[weekKey]',
    MY_CLASS_ANNOUNCEMENT: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/announcement',
    MY_CLASS_ANNOUNCEMENT_DETAIL:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/announcement/[announcementId]',
    MY_CLASS_COURSE_INFO: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/course-info',
    MY_CLASS_ATTENDANCE: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/attendance',
    MY_CLASS_FINAL_TEST: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/final-test',
    MY_CLASS_SAT_TEST: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/sat-test',
    MY_CLASS_DICTATION: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/dictation',
    MY_CLASS_MEMORIES: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/memories',
    MY_CLASS_CERTIFICATION: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/certification',

    SAT_TEST: {
      START: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/sat-test/tests/[id]/start',
      PROGRESS: {
        DO_TEST: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/sat-test/progress/[id]',
        RESULT: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/sat-test/progress/[id]/result',
      },
    },

    STUDENT_COURSE_ASSIGNMENT_PREVIEW:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/course-assignment/[courseAssignmentId]/preview',
    STUDENT_COURSE_ASSIGNMENT_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/course-assignment/[courseAssignmentId]/start',
    STUDENT_COURSE_ASSIGNMENT_REVIEW:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/course-assignment/[courseAssignmentId]/review',
    STUDENT_COURSE_ASSIGNMENT_AI_CORRECTION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/course-assignment/[courseAssignmentId]/ai-correction',
    START_ASSIGNMENT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/assignment/[assignmentId]/start',
    PREVIEW_ASSIGNMENT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/assignment/[assignmentId]/preview',
    PREVIEW_CORRECTION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/assignment/progress/[progressId]/preview',
    MY_CLASS_VOCAB: '/my-classes-trial/[trialCourseKey]/[trialSessionId]/vocab',
    MY_CLASS_VOCAB_DETAIL:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/vocab/[courseVocabSetId]',

    // Learning Vocab
    STUDENT_VOCAB_TEST_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/test/[progressId]/start',
    STUDENT_VOCAB_TEST:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/test/[progressId]',
    STUDENT_VOCAB_TEST_START_NAVIGATION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/test/start-navigation',
    STUDENT_VOCAB_TEST_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/test/[progressId]/result',
    STUDENT_VOCAB_LEARN_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/learn/[progressId]/start',
    STUDENT_VOCAB_LEARN_START_NAVIGATION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/learn/start-navigation',
    STUDENT_VOCAB_LEARN:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/learn/[progressId]',
    STUDENT_VOCAB_LEARN_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/learn/[progressId]/result',
    STUDENT_VOCAB_FLASHCARD:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/flashcard/[courseVocabSetId]',
    STUDENT_VOCAB_FLASHCARD_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/flashcard/[courseVocabSetId]/result',
    STUDENT_VOCAB_MATCHING_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/matching/[progressId]/start',
    STUDENT_VOCAB_MATCHING_START_NAVIGATION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/matching/start-navigation',
    STUDENT_VOCAB_MATCHING:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/matching/[progressId]',
    STUDENT_VOCAB_REVIEW_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/review/[progressId]/start',
    STUDENT_VOCAB_REVIEW:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/review/[progressId]',
    STUDENT_VOCAB_REVIEW_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/review/[progressId]/result',
    STUDENT_VOCAB_WRITE_SENTENCE:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/write-sentence/[courseVocabSetId]',
    STUDENT_VOCAB_WRITE_SENTENCE_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/write-sentence/[courseVocabSetId]/start',
    STUDENT_VOCAB_WRITE_SENTENCE_START_REMAINING:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/write-sentence/[courseVocabSetId]/start-remaining',
    STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/write-sentence/progress/[progressId]',
    STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/write-sentence/progress/[progressId]/result',
    STUDENT_VOCAB_PRONUNCIATION:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/pronunciation/[courseVocabSetId]',
    STUDENT_VOCAB_PRONUNCIATION_START:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/pronunciation/[courseVocabSetId]/start',
    STUDENT_VOCAB_PRONUNCIATION_START_REMAINING:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/pronunciation/[courseVocabSetId]/start-remaining',
    STUDENT_VOCAB_PRONUNCIATION_PROGRESS:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/pronunciation/progress/[progressId]',
    STUDENT_VOCAB_PRONUNCIATION_PROGRESS_RESULT:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/learning-vocab/pronunciation/progress/[progressId]/result',

    // Standard Attendance Summary
    STUDENT_STANDARD_ATTENDANCE_SUMMARY:
      '/my-classes-trial/[trialCourseKey]/[trialSessionId]/standard-attendance-summary?syllabusCode=[syllabusCode]&sessionIndex=[sessionIndex]',
  },
  dictionary: {
    SEARCH_RESULT: '/static/search-result',
  },
  midFinalTest: {
    TEACHER_FINAL_TEST_ALL_OVERVIEW: '/all/overview',
    TEACHER_FINAL_TEST_MY_IN_MARKING: '/me/in-marking',
    TEACHER_FINAL_TEST_MY_CURRENT_INPUT: '/me/current-input',
    TEACHER_FINAL_TEST_ALL_NOT_STARTED_INPUT: '/all/not-started-input',
    TEACHER_FINAL_TEST_ALL_WAITING_FOR_MARK: '/all/waiting-for-mark',
    TEACHER_FINAL_TEST_ALL_STUDENT_SUBMITTED_DROP: '/all/student-submit-drop',
    TEACHER_FINAL_TEST_MY_MARKED: '/me/marked',
    TEACHER_FINAL_TEST_MY_COURSE_LIST: '/me/listing',
    TEACHER_FINAL_TEST_LIST: '/all/listing',
    TEACHER_FINAL_TEST_ALL_TEACHER_STATISTIC: '/all/teacher-statistic',
    TEACHER_FINAL_TEST_COURSE_MANAGEMENT: '/all/course-management',
    TEACHER_FINAL_TEST_ALL_TEACHER_STATISTIC_DETAIL: '/all/teacher-statistic/[teacherId]',
    TATM_FINAL_TEST_ALL_TEACHER_STATISTIC_DETAIL: '/tatm/all/ta-statistic/[taId]',

    TATM_FINAL_TEST_ALL_OVERVIEW: '/tatm/all/overview',
    TATM_FINAL_TEST_MY_IN_MARKING: '/tatm/me/in-marking',
    TATM_FINAL_TEST_MY_CURRENT_INPUT: '/tatm/me/current-input',
    TATM_FINAL_TEST_ALL_NOT_STARTED_INPUT: '/tatm/all/not-started-input',
    TATM_FINAL_TEST_ALL_WAITING_FOR_MARK: '/tatm/all/waiting-for-mark',
    TATM_FINAL_TEST_ALL_STUDENT_SUBMITTED_DROP: '/tatm/all/student-submit-drop',
    TATM_FINAL_TEST_MY_MARKED: '/tatm/me/marked',
    TATM_FINAL_TEST_MY_COURSE_LIST: '/tatm/me/listing',
    TATM_FINAL_TEST_LIST: '/tatm/all/listing',
    TATM_FINAL_TEST_ALL_TEACHER_STATISTIC: '/tatm/all/ta-statistic',
    TATM__FINAL_TEST_ALL_TEACHER_STATISTIC_DETAIL: '/tatm/all/ta-statistic/[taId]',

    TEACHER_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET_ROLLING:
      '/teacher/course-sheet/final-test/student/[userFinalTestId]/[courseSheetFinalTestId]/detail',
    TEACHER_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET_NORMAL:
      '/teacher/course-sheet/final-test/student/[userFinalTestId]/detail',

    ECCS_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET:
      '/eccs/course-sheet/final-test/student/[userFinalTestId]/[courseSheetFinalTestId]/detail',
    ECCS_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET_NORMAL:
      '/eccs/course-sheet/final-test/student/[userFinalTestId]/detail',
    COURSE_SHEET_FINAL_TEST_MANAGE: '/eccs/course-sheet/final-test/manage',
    COURSE_SHEET_FINAL_TEST_ALL_STUDENT_TEST: '/eccs/course-sheet/final-test/all-student-test',
    COURSE_SHEET_FINAL_TEST_ALL_STUDENT_TEST_DETAIL:
      '/eccs/course-sheet/final-test/all-student-test/[hsObjectId]',
    COURSE_SHEET_FINAL_TEST_ALL_INACTIVE: '/eccs/course-sheet/final-test/all-inactive',
    COURSE_SHEET_FINAL_TEST_ALL_IN_PROGRESS: '/eccs/course-sheet/final-test/all-in-progress',
    COURSE_SHEET_FINAL_TEST_DO_OFFLINE_INPUT: '/eccs/course-sheet/final-test/do-offline-input',
    COURSE_SHEET_FINAL_TEST_NOT_STARTED_OFFLINE_INPUT:
      '/eccs/course-sheet/final-test/not-started-offline-input',
    COURSE_SHEET_FINAL_TEST_ALL_SUBMITTED_DROP: '/eccs/course-sheet/final-test/all-submitted-drop',
    COURSE_SHEET_FINAL_TEST_ALL_SUBMITTED: '/eccs/course-sheet/final-test/all-submitted',

    FINAL_TEST_MANAGEMENT_ALL_TEST_LISTING: '/course-data-entry/final-test',
    FINAL_TEST_MANAGEMENT_CREATE_TEST: '/course-data-entry/final-test/create',
    FINAL_TEST_MANAGEMENT_EDIT_TEST: '/course-data-entry/final-test/[testId]',
    FINAL_TEST_MANAGEMENT_HISTORY_TEST: '/course-data-entry/final-test/history/[revisionId]',

    TEACHER_UNIFY_TEST_MARKING: '/teacher/[userFinalTestId]/marking',
    TEACHER_UNIFY_TEST_MARKING_WITH_COURSE_SHEET:
      '/teacher/[userFinalTestId]/[courseSheetFinalTestId]/marking',
    ECCS_UNIFY_TEST_MARKING: '/eccs/[userFinalTestId]/marking',
    ECCS_UNIFY_TEST_MARKING_WITH_COURSE_SHEET:
      '/eccs/[userFinalTestId]/[courseSheetFinalTestId]/marking',
    TEACHER_OFFLINE_TEST_MARKING: '/teacher/offline-test/[finalTestId]/marking',
    TEACHER_ONLINE_TEST_MARKING: '/teacher/online-test/[finalTestId]/marking',
    START_DOING_FINAL_TEST: '/user-test/[userFinalTestId]/start',
    VIEW_SUBMITTED_FINAL_TEST: '/user-test/[userFinalTestId]/view-submitted',
    VIEW_MARKED_FINAL_TEST: '/user-test/[userFinalTestId]/view-marked',
    FINAL_TEST_LOGIN: '/user-test/[userFinalTestId]',

    PREVIEW_FINAL_TEST_SKILL_PART_USER:
      '/preview/final-test/[mode]/[skillType]/[finalTestId]/user-part',
    PREVIEW_FINAL_TEST_SKILL_PART_STUDENT:
      '/preview/final-test/[mode]/[skillType]/[finalTestId]/student-part',
    PREVIEW_FINAL_TEST_READING_PART: '/preview/[resourceId]/reading/[testType]/part',
    PREVIEW_FINAL_TEST_LISTENING_PART: '/preview/[resourceId]/listening/[testType]/part',
    PREVIEW_FINAL_TEST_WRITING_PART: '/preview/[resourceId]/writing/part',
    PREVIEW_FINAL_TEST_SPEAKING_PART: '/preview/[resourceId]/speaking/part',
    PREVIEW_FINAL_TEST_GRAMMAR_PART: '/preview/[resourceId]/grammar/part',
    PREVIEW_FINAL_TEST_VOCAB_PART: '/preview/[resourceId]/vocab/part',
    PREVIEW_FINAL_TEST_SKILL_FULL: '/preview/full/[finalTestId]',
    PREVIEW_FINAL_TEST_SKILL_FULL_USER_OFFLINE: '/preview/full/user/offline/[finalTestId]',
    PREVIEW_FINAL_TEST_SKILL_FULL_USER_ONLINE: '/preview/full/user/online/[finalTestId]',
    PREVIEW_ONLINE_USER_FINAL_TEST_READING:
      '/online-test/user-test/[userFinalTestId]/reading/preview',
    PREVIEW_ONLINE_USER_FINAL_TEST_LISTENING:
      '/online-test/user-test/[userFinalTestId]/listening/preview',
    ECCS_COURSE_SHEET_FINAL_TEST_CREATE: '/eccs/course-sheet/final-test/[courseKey]/create',
    ECCS_COURSE_SHEET_FINAL_TEST_VIEW_DETAIL: '/eccs/course-sheet/final-test/view/[id]',
    STUDENT_PUBLIC_COURSE_SHEET_FINAL_TEST_VIEW_DETAIL:
      '/sheet/final-test/public/[userFinalTestId]/result',
    STUDENT_PUBLIC_COURSE_SHEET_FINAL_TEST_SUBCLASS_VIEW_DETAIL:
      '/sheet/final-test/public/[userFinalTestId]/[subClassKey]/result',
    ECCS_COURSE_SHEET_FINAL_TEST_ROLLING_COURSE_CREATE:
      '/eccs/course-sheet/final-test/[courseKey]/[subClassKey]/create',
    COURSE_SHEET_FINAL_TEST_STUDENT_LINK: '/sheet/final-test/[id]',
    COURSE_SHEET_FINAL_TEST_STUDENT_LINK_WITH_SUB_CLASS: '/sheet/final-test/[id]/[subClassKey]',
    COURSE_SHEET_FINAL_TEST_STUDENT_LINK_DO_INPUT: '/sheet/final-test/[id]/offline-input',
    COURSE_SHEET_FINAL_TEST_STUDENT_LINK_WITH_SUB_CLASS_DO_INPUT:
      '/sheet/final-test/[id]/[subClassKey]/offline-input',
    COURSE_SHEET_FINAL_TEST_STUDENT_LOGIN: '/sheet/final-test/[id]/login',
    COURSE_SHEET_FINAL_TEST_STUDENT_LOGIN_WITH_SUB_CLASS:
      '/sheet/final-test/[id]/[subClassKey]/login',

    TEACHER_COURSE_SHEET_FINAL_TEST_VIEW_DETAIL: '/teacher/course-sheet/final-test/view/[id]',
    ENGINEER_DELETE_COURSE_APP_SHEET: '/engineer/delete-course-app-sheet',
    PREVIEW_BASE_PROGRESS_TEST_SKILL: '/preview/test/[baseProgressId]/[skill]',
    CDCM_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET:
      '/center-director/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/[courseSheetFinalTestId]/detail',
    TEACHER_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET:
      '/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/[courseSheetFinalTestId]/detail',
    CDCM_COURSE_UNIFY_TEST_MARKING_WITH_COURSE_SHEET:
      '/center-director/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/[courseSheetFinalTestId]/marking',
    CDCM_COURSE_UNIFY_TEST_MARKING:
      '/center-director/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/marking',
    TEACHER_COURSE_UNIFY_TEST_MARKING_WITH_COURSE_SHEET:
      '/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/[courseSheetFinalTestId]/marking',
    TEACHER_COURSE_UNIFY_TEST_MARKING:
      '/course-management/courses/[subClassKey]/detail/final-test/[userFinalTestId]/marking',
    TATM_UNIFY_TEST_MARKING: '/tatm/[userFinalTestId]/marking',
    TEACHER_COURSE_DETAIL_VIEW_SUMMARY:
      '/course-management/courses/[subClassKey]/detail/teacher-lesson-summary',

    TEACHER_V2_UNIFY_TEST_MARKING: '/[userFinalTestId]/marking',
    TEACHER_V2_USER_FINAL_TEST_STUDENT_DETAIL_WITH_COURSE_SHEET:
      '/course-sheet/final-test/student/[userFinalTestId]/[courseSheetFinalTestId]/detail',
    CDCM_COURSE_DETAIL_VIEW_SUMMARY:
      '/center-director/course-management/courses/[subClassKey]/detail/teacher-lesson-summary',
  },
};

export const dynamicRoutingHrefs = {
  startTestById: (id, testType) => {
    if (testType === 'FULL_TEST') {
      return {
        pathname: routingUrls.landing.STUDENT_START_FULL_TEST_BY_ID,
        query: { testId: id },
      };
    }
    return {
      pathname: routingUrls.landing.STUDENT_START_PRACTICE_TEST_BY_ID,
      query: { testSectionId: id },
    };
  },
};
